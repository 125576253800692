.page {
  display: grid;
  height: 100%;
  margin: 0;
  padding: 0;
  place-items: center;
  position: relative;
  width: 100%;
  flex: 1;
  max-width: 700px;

  // TODO: move to custom component "Divider [label]"
  .hr {
    text-align: center;
    border: 0;
    border-top: 1px solid #ccc;
    display: block;
    margin: 1.5em auto 0;
    overflow: visible;
    width: 100%;

    &:before {
      background: #fff;
      color: #888;
      content: "oder";
      padding: 0 0.4rem;
      position: relative;
      top: -0.6rem;
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .card {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}
