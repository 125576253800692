.content {
  button {
    width: 100%;
  }

  // TODO: move to custom component "Divider [label]"
  hr {
    border: 0;
    border-top: 1px solid #ccc;
    display: block;
    margin: 1.5em auto 0;
    overflow: visible;
    text-align: center;

    &:before {
      background: #fff;
      color: #888;
      content: "oder";
      padding: 0 0.4rem;
      position: relative;
      top: -0.6rem;
    }
  }
}

.error {
  background: #157efb;
  border-radius: 0.3rem;
  font-weight: 500;

  .message {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.2rem;
    padding: 0.5rem 1rem;
    text-align: left;
  }
}

.resetHint {
  margin-bottom: 18px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.4rem;

  label {
    text-align: right;
  }

  label {
    font-weight: 500;
    margin-bottom: 0.25rem;
    text-align: left;
  }

  .input {
    display: flex;
    flex-direction: column;
  }
}

.suffix {
  padding: 4px 16px;
  cursor: pointer;
}

.agbLink {
  text-decoration: underline;
  cursor: pointer;
}

.errorContainer {
  padding-top: 0.3rem;
}

.errorMsg {
  color: #c94b4b;
  margin: 0 0 8px;
}
