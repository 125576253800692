@use "styles/base";

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.07);
  border-radius: 20px;
  width: min(900px, 85%);
  @include base.responsive {
    width: 100%;
    padding: 20px;
    border-radius: 12px;
  }

  &.fullWidth {
    width: 100%;
  }

  .header {
    font-weight: 700;
    font-size: 2rem;
    line-height: 44px;
    text-align: center;
    color: #14343e;
    @include base.responsive {
      font-size: 1.8rem;
    }
  }
}
